import Logger, { logLevels } from "./core/internal/logger"
import HttpRequest from "./hackle/http/index.browser"
import HackleClientImpl, { BrowserHackleClient as HackleClient, DevTools, PageView } from "./hackle/index.browser"
import {
  BROWSER_BATCH_SIZE,
  BROWSER_FLUSH_INTERVAL,
  BROWSER_MIN_POOL_INTERVAL,
  DEFAULT_CLIENT_SDK_URL,
  DEFAULT_EVENT_URL,
  DEFAULT_MONITORING_URL,
  DEFAULT_SESSION_TIMEOUT_MILLIS,
  IAM_RENDERER_HIDE_PREFIX,
  LOCAL_STORAGE_KEY_PREFIX,
  METRIC_FLUSH_INTERVAL,
  OVERRIDE_AB_STORAGE_PREFIX,
  OVERRIDE_FF_STORAGE_PREFIX,
  SDK_KEY_HEADER,
  SDK_NAME_HEADER,
  SDK_VERSION,
  SDK_VERSION_HEADER
} from "./config"
import EventDispatcher from "./hackle/event/dispatcher/index.browser"
import PollingWorkspaceFetcher from "./core/internal/workspace/PollingWorkspaceFetcher"
import { getUserId, HackleUserResolver, removeUserId, setUserId } from "./hackle/user/index.browser"
import "core-js/features/promise"
import "core-js/features/array"
import EventProcessorImpl, { ExposureEventDedupDeterminer } from "./hackle/event/processor/index.browser"
import HackleCore from "./core/HackleCore"
import { GlobalErrorHandler } from "./hackle/trace/GlobalErrorHandler"
import { EventRepositoryImpl } from "./hackle/event/repository/index.browser"
import { UserManagerImpl, UserStorage } from "./hackle/user/manager/index.browser"
import { SessionManagerImpl } from "./hackle/session/manager/index.browser"
import { cookieStorage } from "./hackle/storage/CookieStorage.browser"
import { SessionEventTracker } from "./hackle/session/track/index.browser"
import { KeyTransformStorage, ListStorage } from "./core/internal/storage/Storage"
import { MonitoringMetricRegistry } from "./core/internal/metrics/monitoring/MonitoringMetricRegistry"
import { Metrics } from "./core/internal/metrics/Metrics"
import { HackleUserManualOverrideStorage } from "./core/internal/evaluation/target/ManualOverrideStorage"
import { HackleUserExplorerBase, HackleUserExplorerImpl } from "./core/internal/user/UserExplorer"
import HackleUrlResolver from "./hackle/http/HackleUrlResolver"
import { InAppMessageTriggerManager } from "./core/internal/iam/InAppMessageTriggerManager"
import { InAppMessageRenderManager } from "./hackle/iam/InAppMessageRenderManager"
import {
  HackleIAMActionEventDetail,
  HackleIAMCloseEventDetail,
  HackleIAMHideEventDetail,
  HackleIAMImpressionEventDetail
} from "./hackle/iam/InAppMessageRenderer"
import { InAppMessageContextMessageDto, InAppMessageDto } from "./core/internal/workspace/iam"
import { User } from "./core/internal/model/model"
import { isSameUser, UserManager } from "./core/internal/user/UserManager"
import { SessionManager } from "./core/internal/session/SessionManager"

const log = Logger.log

let hackleClientCache: HackleClient | null = null

interface Config {
  user?: User

  debug?: boolean
  auto_track_page_view?: boolean
  pollingIntervalMillis?: number
  exposureEventDedupIntervalMillis?: number
  sessionTimeoutMillis?: number
  devTool?: DevTools["manager"]
  autoOpenDevTool?: boolean

  [key: string]: any
}

interface InternalConfig {
  user?: User

  debug: boolean
  log_disabled: boolean
  auto_track_page_view: boolean
  pollingIntervalMillis: number
  exposureEventDedupIntervalMillis: number
  sessionTimeoutMillis: number
  devTool?: DevTools["manager"]
  autoOpenDevTool?: boolean

  sdkUrl: string
  eventUrl: string
  monitoringUrl: string
  rendererScriptUrl: string

  SDK_NAME_HEADER: string
  SDK_VERSION_HEADER: string
}

const defaultConfig: InternalConfig = {
  user: undefined,

  debug: false,
  log_disabled: false,
  auto_track_page_view: true,
  pollingIntervalMillis: -1,
  exposureEventDedupIntervalMillis: -1,
  sessionTimeoutMillis: DEFAULT_SESSION_TIMEOUT_MILLIS,
  autoOpenDevTool: false,

  sdkUrl: DEFAULT_CLIENT_SDK_URL,
  eventUrl: DEFAULT_EVENT_URL,
  monitoringUrl: DEFAULT_MONITORING_URL,
  rendererScriptUrl: InAppMessageRenderManager.RENDERER_SCRIPT,

  SDK_NAME_HEADER: "javascript-sdk_browser",
  SDK_VERSION_HEADER: SDK_VERSION
}

function createInstance(sdkKey: string, _config?: Config): HackleClient {
  const config: InternalConfig = {
    ...defaultConfig,
    ..._config
  }

  Logger.initCounter((logLevel) => Metrics.counter("log", { level: logLevel }))

  if (config.log_disabled) {
    Logger.setLogLevel(logLevels.DISABLE)
  } else {
    if (config.debug) {
      Logger.setLogLevel(logLevels.DEBUG)
    }
  }

  log.debug("sdkKey : " + sdkKey)
  if (!sdkKey) {
    log.error("SDK Key must not be null")
  }

  if (hackleClientCache) {
    log.debug("use already exists hackleClient")
    return hackleClientCache
  }

  let useBeacon = false

  if (typeof window !== "undefined") {
    // @ts-ignore
    useBeacon = window && window.navigator && window.navigator.sendBeacon && true
    if (useBeacon) {
      log.debug("support sendBeacon API")
    }
  }

  const eventDispatcher = new EventDispatcher(sdkKey, HttpRequest, {
    dispatchUrl: HackleUrlResolver.clientDispatch(config.eventUrl),
    beaconDispatchUrl: HackleUrlResolver.beaconDispatch(config.eventUrl, sdkKey),
    useBeacon: useBeacon,
    headers: {
      [SDK_NAME_HEADER]: config.SDK_NAME_HEADER as string,
      [SDK_VERSION_HEADER]: config.SDK_VERSION_HEADER as string
    }
  })

  let pollingIntervalMillis = -1
  if (
    config.pollingIntervalMillis !== undefined &&
    typeof config.pollingIntervalMillis === "number" &&
    config.pollingIntervalMillis > 0
  ) {
    pollingIntervalMillis = Math.max(config.pollingIntervalMillis, BROWSER_MIN_POOL_INTERVAL)
  }

  const workspaceFetcher = new PollingWorkspaceFetcher(sdkKey, HttpRequest, {
    fetchUrl: HackleUrlResolver.clientFetch(config.sdkUrl, sdkKey),
    updateInterval: pollingIntervalMillis,
    headers: {
      [SDK_NAME_HEADER]: config.SDK_NAME_HEADER as string,
      [SDK_VERSION_HEADER]: config.SDK_VERSION_HEADER as string
    }
  })

  let dedupIntervalMillis: number = -1
  if (
    config.exposureEventDedupIntervalMillis !== undefined &&
    typeof config.exposureEventDedupIntervalMillis === "number" &&
    config.exposureEventDedupIntervalMillis !== -1
  ) {
    if (config.exposureEventDedupIntervalMillis < 1000 || config.exposureEventDedupIntervalMillis > 1000 * 60 * 60) {
      log.warn(
        "Exposure event dedup interval is outside allowed range[1_000ms..3_600_000ms]. Setting to default value[no dedup]."
      )
      dedupIntervalMillis = -1
    } else {
      dedupIntervalMillis = config.exposureEventDedupIntervalMillis
    }
  }

  const repository = new EventRepositoryImpl(
    window.localStorage,
    `${LOCAL_STORAGE_KEY_PREFIX}_${sdkKey}`,
    BROWSER_BATCH_SIZE
  )

  const userStorage = new UserStorage(KeyTransformStorage.postfix(cookieStorage, `_${sdkKey}`))
  const previousUser = userStorage.getUser()
  const initUser = config.user || null
  const userManager = new UserManagerImpl(userStorage, getUserId(), previousUser, initUser)

  const sessionManager = new SessionManagerImpl(
    config.sessionTimeoutMillis,
    KeyTransformStorage.postfix(cookieStorage, `_${sdkKey.slice(8)}`)
  )
  userManager.addListener(sessionManager)

  const dedupDeterminer = new ExposureEventDedupDeterminer(dedupIntervalMillis)
  const eventProcessor = new EventProcessorImpl(
    eventDispatcher,
    BROWSER_BATCH_SIZE,
    BROWSER_FLUSH_INTERVAL,
    repository,
    dedupDeterminer,
    sessionManager,
    userManager
  )

  const abOverrideStorage = new HackleUserManualOverrideStorage(
    new ListStorage(cookieStorage, `${OVERRIDE_AB_STORAGE_PREFIX}_${sdkKey.slice(0, 8)}`)
  )
  const ffOverrideStorage = new HackleUserManualOverrideStorage(
    new ListStorage(cookieStorage, `${OVERRIDE_FF_STORAGE_PREFIX}_${sdkKey.slice(0, 8)}`)
  )

  const core = HackleCore.create(workspaceFetcher, eventProcessor, [abOverrideStorage, ffOverrideStorage])
  const inAppMessageTriggerManager = new InAppMessageTriggerManager(core)
  const hackleUserResolver = new HackleUserResolver(userManager)
  const userExplorer = new HackleUserExplorerImpl(
    core,
    userManager,
    hackleUserResolver,
    abOverrideStorage,
    ffOverrideStorage
  )

  const isValidDevToolConfig = config.devTool?.userExplorer && typeof config.devTool?.userExplorer === "function"
  const devTools = config.devTool && isValidDevToolConfig ? { manager: config.devTool, userExplorer } : undefined

  const hackleClient = new HackleClientImpl(core, hackleUserResolver, sessionManager, userManager, devTools)
  const inAppMessageRenderManager = new InAppMessageRenderManager(
    inAppMessageTriggerManager,
    window.localStorage,
    IAM_RENDERER_HIDE_PREFIX,
    hackleClient,
    { rendererScriptUrl: config.rendererScriptUrl }
  )
  const sessionEventTracker = new SessionEventTracker(hackleUserResolver, core)
  sessionManager.addListener(sessionEventTracker)

  hackleClientCache = hackleClient

  if (config.autoOpenDevTool) {
    if (!config.devTool) {
      log.error("DevTool is not provided")
    } else {
      hackleClient.showUserExplorer()
    }
  }

  const drainRepository = () => {
    eventProcessor.drainRepository()
  }

  const monitoringMetricRegistry = new MonitoringMetricRegistry(
    config.monitoringUrl,
    METRIC_FLUSH_INTERVAL,
    HttpRequest,
    {
      [SDK_NAME_HEADER]: config.SDK_NAME_HEADER,
      [SDK_VERSION_HEADER]: config.SDK_VERSION_HEADER,
      [SDK_KEY_HEADER]: sdkKey
    },
    useBeacon
  )

  const flush = () => {
    removeUserId()
    sessionManager.updateLastEventTime(new Date().getTime())
    hackleClient.close()
    Metrics.globalRegistry.close()
  }

  document.addEventListener("visibilitychange", () => {
    if (!document.hidden) {
      drainRepository()
    }
  })

  window.addEventListener("pageshow", drainRepository)

  window.addEventListener("onpagehide" in window ? "pagehide" : "unload", flush)

  Metrics.addRegistry(monitoringMetricRegistry)

  hackleClient.onReady(() => {
    _initializeSession(previousUser, userManager, sessionManager)
    new GlobalErrorHandler().install(core, hackleUserResolver)
    _installPageViewAutoTrack(hackleClient, config)
  })

  return hackleClient
}

function _initializeSession(previousUser: User | null, userManager: UserManager, sessionManager: SessionManager) {
  const currentUser = userManager.currentUser
  if (isSameUser(previousUser, currentUser)) {
    sessionManager.startNewSessionIfNeeded(currentUser, Date.now())
  } else {
    sessionManager.startNewSession(previousUser, currentUser, Date.now())
  }
}

function _installPageViewAutoTrack(client: HackleClient, config: InternalConfig) {
  if (!config.auto_track_page_view) {
    return
  }
  client.trackPageView()

  function customEvent(type: string): Event {
    if (typeof window.Event === "function") return new Event(type)

    const params = { bubbles: false, cancelable: false, detail: undefined }
    const evt = document.createEvent("CustomEvent")
    evt.initCustomEvent(type, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  try {
    history.pushState = ((f) =>
      function pushState() {
        try {
          // @ts-ignore
          var ret = f.apply(this, arguments)
          window.dispatchEvent(customEvent("locationchange"))
          return ret
        } catch (e) {
          if (e instanceof Error) {
            log.error(e)
          } else {
            try {
              log.error(e as string)
            } catch (ex) {}
          }
        }
      })(history.pushState)

    history.replaceState = ((f) =>
      function replaceState() {
        try {
          // @ts-ignore
          var ret = f.apply(this, arguments)
          window.dispatchEvent(customEvent("locationchange"))
          return ret
        } catch (e) {
          if (e instanceof Error) {
            log.error(e)
          } else {
            try {
              log.error(e as string)
            } catch (ex) {}
          }
        }
      })(history.replaceState)

    window.addEventListener("popstate", () => {
      try {
        window.dispatchEvent(customEvent("locationchange"))
      } catch (e) {
        if (e instanceof Error) {
          log.error(e)
        } else {
          try {
            log.error(e as string)
          } catch (ex) {}
        }
      }
    })
    window.addEventListener("locationchange", () => {
      try {
        client.trackPageView()
      } catch (e) {
        if (e instanceof Error) {
          log.error(e)
        } else {
          try {
            log.error(e as string)
          } catch (ex) {}
        }
      }
    })
  } catch (e) {
    if (e instanceof Error) {
      log.error(e)
    } else {
      try {
        log.error(e as string)
      } catch (ex) {}
    }
  }
}

export { PropertyOperationsBuilder, PropertyOperations } from "./hackle/property/PropertyOperations"
export { HackleUserExplorerBase }
export { createInstance }
export { getUserId }
export { setUserId }
export { removeUserId }
export { HackleClient }
export { PageView }
export { Logger }
export { Config }
export {
  InAppMessageDto,
  InAppMessageContextMessageDto,
  HackleIAMImpressionEventDetail,
  HackleIAMActionEventDetail,
  HackleIAMCloseEventDetail,
  HackleIAMHideEventDetail
}

export * from "./core/internal/model/model"

export default {
  createInstance,
  getUserId,
  setUserId,
  removeUserId,
  Logger
}
